<template>
<div>
	<CRow>
		<CCol sm="8" md="8">
        <CCard>
          <CCardHeader>
            <CRow>
             <CCol col="9" class="text-left"><h4>Credit User</h4></CCol>
             <CCol col="3" class="text-right">
                    <b-form-checkbox
                      v-model="cbbfile"
                      @change="onModifycbbfile"
                      name="cbbfile"
                      value="true"
                      unchecked-value="false">
                      Batch Credit
                    </b-form-checkbox>
             </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>

           <CForm>

              <!-- b-row>
                  <b-col sm="3">
                     <label for="input-small" >Phone Number</label>
                  </b-col>
                  <b-col sm="9">
                      <vue-typeahead-bootstrap
                        v-model="phoneNum"
                        :data="optNumber">
                        <template #append>
                          <CButton color="success" :disabled="!isDisabledSlc" v-on:click="actionChange()">Select</CButton>
                        </template>
                      </vue-typeahead-bootstrap>
                   </b-col>
                  </b-col>
              </b-row -->
							<CInput
                type="number"
								id="phoneNumberSearch"
                class="phoneNumber"
								description="Please add country code within phone number (62, 65, etc)"
								autocomplete="off"
                label="Phone Number"
                v-model="phoneNum"
								horizontal
              >
							<template #append>
									<CButton color="success" :disabled="!isDisabledSlc" v-on:click="actionChange()">Select</CButton>
								</template>
							</CInput>

							<CRow>
                <CCol col="12" class="text-left">
                  <CAlert v-bind:color="coloruser" v-if="seenuser" id="alertuser">{{ msguser }}</CAlert>
                </CCol>
              </CRow>

              <div v-if="seenfile">
                  <b-row>
                    <b-col sm="3">
                       <label for="input-small" >Upload File </label>
                    </b-col>
                    <b-col sm="9">
                       <input type="file" :disabled="!isDisabledfile" @change="onFileChange" />
                    </b-col>
                  </b-row><br/>

                  <b-row>
                    <div class="table-responsive table">
                      <b-table striped hover  :items="items" :fields="fields"
                      :current-page="currentPage" :per-page="perPage" :filter="filter">

                      </b-table>
                    </div>
                  </b-row><br/>

                  <b-row>
                    <b-col sm="3">
                      <b-pagination size="md" :total-rows="this.items.length" :per-page="perPage" v-model="currentPage" />
                    </b-col>
                    <b-col sm="9">
                      <b-form-select :options="[{text:5,value:5},{text:10,value:10},{text:15,value:15}]" v-model="perPage" style="width:100px;">
                      </b-form-select>
                    </b-col>
                  </b-row>

              </div>

              <CInput
                type="text"
                label="User Identity"
                v-model="userId"
                :readonly="true"
                horizontal
              />

               <CInput
                type="text"
                label="Display Name"
                v-model="displayName"
                :readonly="true"
                horizontal
              />

              <b-row>
                  <b-col sm="3">
                     <label for="input-small" >Earmark Credit</label>
                  </b-col>
                  <b-col sm="9">
												<b-form-checkbox v-model="checked" name="check-button" switch size="lg" @change="earMark($event)">
												</b-form-checkbox>
                   </b-col>
              </b-row><br v-show="hiddenChecked === false" />

							<b-row v-show="hiddenChecked === false">
                <b-col sm="3">
										<label for="input-small">Expired Date</label>
                </b-col>
                <b-col sm="5">
										<CInput type="date" v-model="expiredDate" />
                </b-col>
                <b-col sm="4">
										<vue-timepicker @change="sttimeChange" v-model="expiredTime"
                                    format="HH:mm:ss"></vue-timepicker>
                </b-col>
							</b-row> <br v-show="hiddenChecked === false" />
							<b-row v-show="hiddenChecked === false">
                <b-col sm="3">
										<label for="input-small">Features</label>
                </b-col>
                <b-col sm="9">
										<b-form-select v-model="feature" :options="optFeatures"></b-form-select>
                </b-col>
							</b-row><br v-show="hiddenChecked === false"/>

              <b-row>
                  <b-col sm="3">
                     <label for="input-small" >Wallet Name</label>
                  </b-col>
                  <b-col sm="9">
                      <b-form-select v-model="walletId" :options="optWallet"></b-form-select>
                   </b-col>
              </b-row><br/>

              <b-row>
                  <b-col sm="3">
                     <label for="input-small" >Transaction Type</label>
                  </b-col>
                  <b-col sm="9">
                     <b-form-select v-model="trType" :disabled="!isDisabledtrType"  :options="optTrType"></b-form-select>
                   </b-col>
              </b-row><br/>

              <b-row>
                  <b-col sm="3">
                     <label for="input-small" >Currency</label>
                  </b-col>
                  <b-col sm="9">
                     <b-form-select v-model="currency" :disabled="!isDisabledcurr"  :options="optCurrency"></b-form-select>
                   </b-col>
              </b-row><br/>

              <CInput
                type="number"
                label="Amount"
                v-model="amount"
                :disabled="!isDisabledamount"
                min="1"
                placeholder="Enter Credit Amount"
                horizontal
              />

              <b-row>
                  <b-col sm="3">
                     <label for="input-small" >Customer Message</label>
                  </b-col>
                  <b-col sm="9">
                     <b-form-select v-model="custMsg" @change="onChangeCsMsg" :options="optCustMsg"></b-form-select>
                   </b-col>
              </b-row><br/>

              <span v-if="seenOther">
                <CTextarea
                label="Other Message"
                v-model="otherMsg"
                placeholder="Type Others Message..."
                description="Maximum 50 characters"
                maxlength="50"
                horizontal
                rows="3"
                />
              </span>

              <CTextarea
                label="Reason"
                v-model="reason"
                placeholder="Type Reason..."
                maxlength="180"
                description="Max. 180 characters. For User-requested top-ups, you must include user phone number, date/time requested, purpose and other comments.  Example: 6580134177, 23/3/2024 1PM, To remit"
                horizontal
                rows="3"
              />

             <CInput
                 label="Bank Reference"
                 v-model="comments"
                 placeholder="Type Bank Reference..."
                 description="Enter the Bank Reference here that’s captured in the bank record. Eg: EWTOPUP812DFJ123"
                 maxlength="100"
                 horizontal
             />

              <span v-if="seenOTP" >
                <CInput
                  type="text"
                  label="Tr Reference"
                  v-model="trRef"
                  :readonly="true"
                  horizontal
                />

              </span>
             <b-row>
               <b-col sm="3">is Bank Transfer or Cash?</b-col>
               <b-col sm="9">
                 <input type="radio" id="check_reference_yes" name="check_reference" v-model="checkReference" value="yes" >
                 <label for="reused_image_yes" class="pl-1">Bank Transfer</label> &nbsp;
                 <input type="radio" id="check_reference_no" name="check_reference" v-model="checkReference" value="no">
                 <label for="reused_image_no" class="pl-1">Cash</label>
               </b-col>
             </b-row><br />
              <b-row>
                  <b-col sm="3">FUNDS IN BANK Checked?</b-col>
                <b-col sm="9">
                  <input type="radio" id="funded_bank_yes" name="funded_bank" v-model="fundsBank" value="yes" @change="actionCheckSubmit">
                  <label for="funded_bank_yes" class="pl-1"> Yes</label> &nbsp;
                  <input type="radio" id="funded_bank_no" name="funded_bank" v-model="fundsBank" value="no" @change="actionCheckSubmit">
                  <label for="funded_bank_no" class="pl-1"> No</label>
                </b-col>
              </b-row><br />
              <b-row>
                <b-col sm="3">SLIP DATE, AMOUNT Checked?</b-col>
                <b-col sm="9">
                  <input type="radio" id="slip_date_yes" name="slip_date" v-model="slipDate" value="yes" @change="actionCheckSubmit">
                  <label for="slip_date_yes" class="pl-1">Yes</label> &nbsp;
                  <input type="radio" id="slip_date_no" name="slip_date" v-model="slipDate" value="no" @change="actionCheckSubmit">
                  <label for="slip_date_no" class="pl-1">No</label>
                </b-col>
              </b-row><br />
              <b-row>
               <b-col sm="3">RE-USED IMAGE IN SHAREPOINT Checked?</b-col>
               <b-col sm="9">
                 <input type="radio" id="reused_image_yes" name="reused_image" v-model="reusedImage" value="yes" @change="actionCheckSubmit">
                 <label for="reused_image_yes" class="pl-1">Yes</label> &nbsp;
                 <input type="radio" id="slip_date_no" name="reused_image" v-model="reusedImage" value="no" @change="actionCheckSubmit">
                 <label for="reused_image_no" class="pl-1">No</label>
               </b-col>
              </b-row><br />
              <b-row>
                  <b-col sm="3"></b-col>
                  <b-col sm="9">
                     <b-button-group>
                     <CButton :disabled="isDisableSubmit" v-on:click="actionSubmit()" color="primary">Submit</CButton>
                      &nbsp;
                     &nbsp;
                     <vue-excel-xlsx
                        v-if="seenBtnDwld"
                        style="color:green"
                        :data="data"
                        :columns="columns"
                        :filename="'Batch-Credit-Report'"
                        :sheetname="'Batch-Credit-Report'"
                        >
                        Download Report
                     </vue-excel-xlsx>
                     </b-button-group>
                  </b-col>
              </b-row><br/>

              <b-row v-if="pbar">
                  <b-col sm="12">
                   <b-progress :max="max" height="2rem" show-progress animated>
                    <b-progress-bar :value="value" variant="success" :label="`${((value / max) * 100)}%`"></b-progress-bar>
                  </b-progress>
                  </b-col>
              </b-row><br/>

              <div class="text-center">
                <b-spinner variant="success" label="Spinning" v-if="spinner" ></b-spinner>
              </div>

              <CRow>
                <CCol col="12" class="text-left">
                <CAlert v-bind:color="color" v-if="seen" id="alert"><span v-html="msg"></span></CAlert>
              </CCol>
              </CRow>

            </CForm>

           </CCardBody>
        </CCard>
      </CCol>

     </CRow>

</div>
</template>



<script>
 import AuthService from '@/api/AuthService.js';
 import TransactService from '@/api/TransactService.js';
 import VueTimepicker from 'vue2-timepicker';
 import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
 import readXlsxFile from 'read-excel-file';
 import moment from 'moment';

  export default {
    name: 'credit',
    components: {
      VueTypeaheadBootstrap,
      VueTimepicker
    },
    data() {
			var date = new Date();
			var exprdate = new Date(date);
			exprdate.setFullYear(date.getFullYear() + 5);
      return {
          trRef:'',
          otp:'',
					checked: false,
					hiddenChecked : true,
          seenOTP:false,
          sent:false,
          columns :[
            { label: "Phone Number ", field: "phoneNumber" },
            { label: "User ID ", field: "userId" },
            { label: "Currency", field: "currency"},
            { label: "Amount", field: "amount"},
            { label: "Datetime", field: "dateTime"},
            { label: "Status", field: "status"},
          ],
          optCustMsg:[
            {"value":1, "text" : "Top-up by ATM Cash Deposit"},
            {"value":2, "text" : "Top-up without top-up Code"},
            {"value":3, "text" : "Reward for Moolahgo Promotion"},
            {"value":4, "text" : "Reward for Identity Verification"},
            {"value":5, "text" : "Refund due to Debit error"},
            {"value":6, "text" : "Refund from Trx Cancellation"},
            {"value":7, "text" : "Others"},
          ],
          seenfile:false,
          cbbfile:false,
          fundsBank: '',
          reusedImage: '',
          slipDate: '',
          checkReference: 'yes',
          isDisableSubmit: true,
          isDisabledSlc: true,
          isDisabledfile:true,
          isDisabledcurr:true,
          isDisabledamount:true,
          isDisabledtrType:true,
          seenBtnDwld:false,
          optCurrency: [],
          optNumber:[],
          optWallet:[],
          optFeatures:[],
          phoneNum:'',
          baseItems:[],
          baseItems2:[],
          baseUserId:[],
          walletGroup:[],
					walletGroupAvailable:[],
          errorPhoneNumbers:[],
          basePhoneNum:[],
          optTrType:[],
          reportList:[],
          report:[],
          successList:[],
          failedList:[],
          userId:'',
          displayName:'',
          expiredDate: exprdate.toISOString().substr(0, 10),
          expiredTime: moment.utc(date).local().format('HH:mm:ss'),
          walletId:'',
          trType:'',
          currency:'',
          amount:'',
          reason:'',
          custMsg:'',
          comments:'',
          otherMsg:'',
          feature:'',
          items:[],
          fields: [
            {
              key: 'phoneNumber',
              label : 'Phone Number',
              sortable: true
            },
            {
              key: 'currency',
              label : 'Currency',
              sortable: true
            },
            {
              key: 'amount',
              label : 'Amount',
              sortable: true
            },
          ],
          pbar:false,
          value:0,
          max:0,
          spinner:false,
          currentPage: 1,
          perPage: 5,
          filter: null,
          seenOther: false,
          seen:false,
          msg :'',
          color:'',
          seenuser:false,
          msguser :'',
          coloruser:'',
          url_mg:'',
          xapikey:'',
      };
    },
    created () {
        this.getSystemConfigList();
        this.getConfigList();
        this.actionCheckSubmit();
        this.getWalletGroupList();
    },
    methods: {

      actionResend(){
        this.spinner = true;
        this.debitCreditValidate();
      },

      onChangeCsMsg(){

        if(this.custMsg === 7){
           this.seenOther = true;
        } else { this.seenOther = false; }

      },

      earMark(e){

        if(this.checked){
          this.hiddenChecked = false;
        } else {
          this.hiddenChecked = true;
				}

      },

      getDateTime(){
        var today = new Date();
        var date = today.getFullYear()+'/'+(today.getMonth()+1)+'/'+today.getDate();
        var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        var dateTime = date+' '+time;
        return dateTime;
      },

      async actionSubmit(){
         if(this.cbbfile){
           this.pbar = true; this.spinner = true;
           if(!this.reason || !this.custMsg || !this.walletId || !this.items || !this.comments || !this.reason){
                this.msg   = 'Input Not Complete, All Data Required!';
                this.color = 'warning'; this.seen  = true;
                this.pbar  = false;
                setTimeout( () =>  this.seen=false , 5000);
           } else {
              if(!this.otp){
                if(this.sent == false){
                    this.debitCreditValidate(); this.pbar  = false;
                } else {
                  this.msg   = 'OTP is required!';
                  this.color = 'warning'; this.seen  = true; this.pbar = false;
                  this.spinner = false;
                  setTimeout( () =>  this.seen=false , 5000);
                }
              } else {
                if (confirm('Batch Credit will be processing, Are you sure ? ')) {
                  this.spinner = false;
                  var data  = this.items;   this.max = data.length;  var i = 1;
                  for (var key in data) {
                      var row      = data[key];
                      var phoneNum = row.phoneNumber;
                      const resp   = await AuthService.getUsrDtlByPhone(phoneNum);
											console.log(resp);
											var dis = this;
                      let userId = false;
                      if (resp.hasOwnProperty('userIdentify')) {
                        userId = resp.userIdentify.userId;
                        var wallet_id = resp.wallets[0].walletId;
                        var walletChosen = resp.wallets.filter(w => w.walletGroup.name == dis.walletId);
                        console.log(walletChosen);
                        if (walletChosen.length == 0) {
                          walletChosen = (resp.hasOwnProperty("preferredWalletId")) ? resp.preferredWalletId : wallet_id;
                        }
                        console.log(walletChosen);
                      }
											data[key]['reason'] = this.reason;
                      var idx = this.custMsg - 1;
                      var msg = (this.custMsg === 7) ? this.otherMsg : this.optCustMsg[idx].text;
                      data[key]['customerMessage'] = msg;
                      data[key]['transactionType'] = this.trType;
                      data[key]['userType'] = "USER";
                      data[key]['walletGroup'] = this.walletId;
                      data[key]["walletId"] = "";
                      data[key]["requestRef"] = this.trRef;
                      data[key]["otp"] = this.otp;
                      if(userId){
                          let add = {'userId' : userId};
                          data[key]['userIdentify']  = add;
                          delete data[key]['phoneNumber'];
                          var params = data[key];
                          console.log('submit batch credit');
                          try {
														const check_earmark = await AuthService.earMarkByWalletId(wallet_id); console.log(check_earmark);
														var arr_check = check_earmark.payload.filter(p => p.feature == dis.feature);

                            const resp = await AuthService.creditUser(params);  //console.log(params);
                            if(resp.message=='Success'){
                              params['phoneNumber'] =  phoneNum;
                              params['userId'] =  userId;
                              params['dateTime'] =  this.getDateTime();
                              params['status'] = 'Success';
															if(this.hiddenChecked === false) {
																		let paramEarmark = {};
																		var expr_at = this.expiredDate+' '+this.expiredTime;
																		paramEarmark["expireDate"] = new Date(expr_at).toISOString();
																		if (arr_check.length == 0) {
																				paramEarmark["amount"]   = row.amount;
																				paramEarmark["currency"] = row.currency;
                                        paramEarmark["feature"] = this.feature;
                                        paramEarmark["userId"] = userId;
                                        paramEarmark["walletId"] = wallet_id;
																				const resp_earmark = await AuthService.earMarkSave(paramEarmark); //console.log(resp_earmark);
																		} else {
																				paramEarmark["amount"]   = parseFloat(row.amount) + parseFloat(arr_check[0].amount);
																				paramEarmark["recordId"] = arr_check[0].id;
																				const resp_earmark = await AuthService.earMarkUpdate(paramEarmark);
																		}
															}
                              this.reportList.push(params);
                            } else {
                              params['phoneNumber'] =  phoneNum;
                              params['userId'] =  userId;
                              params['dateTime'] =  this.getDateTime();
                              params['status'] = 'Failed, ' + resp.data.message;
                              this.reportList.push(params);
                            }
                          } catch (err) { console.log(err); }

                      } else {
                          let params = data[key];
                          params['dateTime'] =  this.getDateTime();
                          params['status'] = 'Failed, User Not Found !';
                          this.reportList.push(params);
                      }

                    params = [];
                    this.value = i; i++;
                  }
                  this.completeBatchCredit();
                }
              }
           }

         } else {
            this.spinner = true;
            let params = {}; var validate = [];
            params["amount"]   = this.amount;
            params["currency"] = this.currency;
            params["reason"]   =  this.reason;
            var idx = this.custMsg - 1;
            var msg = (this.custMsg === 7) ? this.otherMsg : this.optCustMsg[idx].text;
            params["customerMessage"] = msg;
            //params["customerMessage"] = this.custMsg;
            params["transactionType"] = this.trType;
            let add = {'userId' : this.userId };
            params["userIdentify"] = add;
            params["userType"] = "USER";
            params["walletId"] =  this.walletId;

            for (var key in params) {
              if(!params[key]) { validate.push(false); } else { validate.push(true); }
            }

            params["otp"] = this.otp;
            params["requestRef"] = this.trRef;
            params["walletGroup"] = 0; console.log(params);

            if(!validate.includes(false)){ console.log('submit single credit');
                if(!this.otp){
                  if(this.sent == false){
                    this.debitCreditValidate();
                  } else {
                    this.msg   = 'OTP is required!';
                    this.color = 'warning'; this.seen  = true; this.spinner = false;
                    setTimeout( () =>  this.seen=false , 5000);
                  }
                } else {
                  try {
										const check_earmark = await AuthService.earMarkByWalletId(this.walletId); console.log(check_earmark);
										var dis = this;
										var arr_check = check_earmark.payload.filter(p => p.feature == dis.feature);

                    const resp = await AuthService.creditUser(params);  console.log(resp);
                    if(resp.message=='Success'){
											if(this.hiddenChecked === false) {
														let paramEarmark = {};
														var expr_at = this.expiredDate+' '+this.expiredTime;
														paramEarmark["expireDate"] = new Date(expr_at).toISOString();
														if (arr_check.length == 0) {
																paramEarmark["amount"]   = this.amount;
																paramEarmark["currency"] = this.currency;
                                paramEarmark["feature"] = this.feature;
                                paramEarmark["userId"] = this.userId;
                                paramEarmark["walletId"] = this.walletId;

																AuthService.earMarkSave(paramEarmark).then(resp_earmark => {
																		console.log(resp_earmark);
																		this.msg   = (resp_earmark.statusCode != "S0000") ? 'Credit user success, but failed to save earmark due to : ' + resp_earmark.message : 'Credit user with earmark successfully done';
																		this.seen  = true; this.spinner = false;
																		this.color = (resp_earmark.statusCode != "S0000") ? 'warning' : 'success';
																		setTimeout( () =>  this.seen=false , 5000);
																}, error => {
                                  console.log('error');
                                });
													} else {
															paramEarmark["recordId"] = arr_check[0].id;
															paramEarmark["amount"]   = parseFloat(arr_check[0].amount) + parseFloat(this.amount);
															AuthService.earMarkUpdate(paramEarmark).then(resp_earmark => {
																	console.log(resp_earmark);
																	this.msg   = (resp_earmark.statusCode != "S0000") ? 'Credit user success, but failed to update earmark due to : ' + resp_earmark.message : 'Credit user with earmark successfully done';
																	this.seen  = true; this.spinner = false;
																	this.color = (resp_earmark.statusCode != "S0000") ? 'warning' : 'success';
																	setTimeout( () =>  this.seen=false , 5000);
															}, error => {
																	console.log('error');
															});
													}
											}
                      this.completeSingleCredit();  this.spinner = false;
                    } else {
                      this.msg   = resp.data.message;
                      this.color = 'danger';  this.seen  = true; this.spinner = false;
                      setTimeout( () => this.seen=false, 5000);
                    }
                  } catch (err) { console.log(err); }
                }

            } else {
              this.msg   = 'Input Not Complete, All Data Required!';
              this.color = 'warning'; this.seen  = true; this.spinner = false;
              setTimeout( () =>  this.seen=false , 5000);
            }
         }
      },

      completeBatchCredit(){
          setTimeout( () =>  this.pbar=false , 5000);
          this.spinner = false;
          this.msg   = "Batch Credit Completed!";
          this.color = 'success';  this.seen=true;
          setTimeout( () => this.seen=false, 5000);
          this.actionReset();
          //console.log(this.reportList);
          this.report = this.reportList;
          this.data   = this.report;
          this.seenBtnDwld = true;
					this.isDisabledcurr = true;
					this.isDisabledamount = true;
					this.checked = false;
					this.hiddenChecked = true;
          this.reportList = [];

      },

      completeSingleCredit(){
          this.msg   = 'Credit user successfully done';
          this.color = 'success';  this.seen  = true;
          setTimeout( () => this.seen=false, 5000);
          this.phoneNum='';
          this.userId='';
          this.displayName='';
          this.trType = '';
          this.walletId='';
          this.currency='';
          this.amount='';
          this.custMsg='';
          this.otherMsg='';
          this.reason='';
          this.trRef='';
          this.comments='';
					this.checked = false;
					this.hiddenChecked = true;
          this.otp = '';
          this.seenOTP = false;
          this.sent = false;
      },

      actionReset(){
          this.items = [];
          this.walletId = "";
          this.trType = "";
          this.custMsg= "";
          this.otherMsg="";
          this.reason = "";
          this.trRef='';
          this.otp = '';
          this.sent = false;
          this.seenOTP = false;
          this.cbbfile = false;
          this.seenfile = false;
          this.isDisabledSlc = true;
      },

      actionChange(){
        if(this.phoneNum){
          this.currency='';
          this.amount='';
          this.reason='';
          var detail = this.getUserDetail(this.phoneNum);
        } else {
          this.msg   = 'Please select phone number first !';
          this.color = 'warning'; this.seen  = true;
          setTimeout( () =>  this.seen=false , 5000);
        }
      },

      actionCheckSubmit() {
        if (this.reusedImage === "yes" && this.slipDate === "yes" && this.fundsBank === "yes") {
           this.isDisableSubmit = false;
        } else {
           this.isDisableSubmit = true;
        }
      },

      stringToBoolean: function(string){
          switch(String(string).toLowerCase().trim()){
              case "true": case "yes": case "1": return true;
              case "false": case "no": case "0": case null: return false;
              default: return Boolean(string);
          }
      },

      onFileChange(event) {
        let xlsxfile = event.target.files ? event.target.files[0] : null;
        readXlsxFile(xlsxfile).then((rows) => {
            var temp = []; var names = ['phoneNumber','currency','amount'];
            rows.splice(0, 1);
            this.msg = '';
						for (var key in rows) {
                let each = {};
                var row = rows[key];
                for (var key2 in row) {
                      const val    = row[key2];
                      let name   = names[key2];
                      each[name] = val;
											if(name == 'phoneNumber'){
													AuthService.getUsrDtlByPhone(val).then(resp => {
															if (resp.hasOwnProperty('wallets')) {
                                resp.wallets.forEach((item, index) => {
                                  if (this.walletGroupAvailable.includes(item.walletGroup.name) !== true) {
                                    this.walletGroupAvailable.push(item.walletGroup.name);
                                  }
                                });
                                temp.push(each);
                              } else {
                                if (!this.seen) {
                                  this.seen = true;
                                }
                                this.color = 'danger';
                                this.msg += '<p>Phone Number : ' + val + ' is not found</p>';
                              }
													}, error => {
															console.log(error);
													});
											}
                }
                if (this.seen) {
                  setTimeout(() => this.seen = false, 5000);
                }
            }
						this.items = temp;
        })
      },

      onModifycbbfile(){

         var checkFile = this.stringToBoolean(this.cbbfile);

         if(checkFile){
              this.seenfile=true; this.isDisabledSlc=false; this.isDisabledfile = true;
              this.isDisabledcurr = false; this.isDisabledamount = false ;
              this.optWallet = this.walletGroup;
              this.phoneNum = ''; this.getWalletGroupList(); this.currency = ''; this.amount = 0;
              this.userId = ''; this.displayName = '';
              console.log(`phones : ` + this.errorPhoneNumbers.join(","));
              if (this.errorPhoneNumbers.length > 0) {
                let phones = this.errorPhoneNumbers.join(', ');
                this.msg   = phones + " are not found in our database";
                this.color = 'danger';
              } else {
                this.msg = "Use excel file (*.xlsx)";
                this.color = 'info';
              }
              this.seen  = true;
              setTimeout( () =>  this.seen=false , 5000);
         } else {
             //console.log('false');
             this.seenfile=false;  this.isDisabledSlc=true; this.isDisabledfile = false;
             this.isDisabledcurr = true; this.isDisabledamount = true;
             this.cbbfile = false; this.text="";
             this.optWallet = [];    this.seenBtnDwld = false;
         }
      },

			sttimeChange() {
         var term = this.expiredTime; var temp = this.expiredTime;

         if (term.match(/HH|mm|ss/g)) {
            var temp = term.replace("HH", "00").replace("mm", "00").replace("ss", "00");
         }

         this.expiredTime = temp;

      },

      debitCreditValidate: function() {
        this.isDisableSubmit = true;
        let params_check = {};
        params_check["bank_reference"] = this.comments
        if (this.checkReference === "yes") {
          params_check["check_reference"] = true
        }
        TransactService.getMatchingBankReference(this.url_mg, this.xapikey, params_check).then(async resp_check => {
          console.log(resp_check)
          if (!resp_check) {
            this.isDisableSubmit = false
            this.msg = 'Request is disabled by CORS';
            this.color = 'warning';
            this.seen = true;
            this.spinner = false;
            setTimeout(() => this.seen = false, 5000);
          } else if (!resp_check.hasOwnProperty('success') || !resp_check.success) {
            this.isDisableSubmit = false
            this.msg = (this.comments == "" ) ? "Bank Reference can't be empty" : resp_check.message;
            this.color = 'warning';
            this.seen = true;
            this.spinner = false;
            setTimeout(() => this.seen = false, 5000);
          } else {
            if (this.checkReference === "yes" && (parseFloat(resp_check.data['txn_amount']) !== parseFloat(this.amount) || resp_check.data['currency_code'] !== this.currency)) {
              let error_msg = '';
              if (resp_check.data['currency_code'] !== this.currency) {
                error_msg += 'Warning! Invalid currency while currency = '+this.currency+ ' and reference currency is = '+resp_check.data['currency_code'];
                if (parseFloat(resp_check.data['txn_amount']) !== parseFloat(this.amount)) {
                  error_msg += ' & Invalid amount while amount = '+this.amount+ ' and reference amount is = '+resp_check.data['txn_amount'];
                }
              } else {
                error_msg += 'Warning! Invalid amount while amount = '+this.amount+ ' and reference amount is = '+resp_check.data['txn_amount'];
              }
              this.msg = error_msg;
              this.color = 'warning';
              this.seen = true;
              this.spinner = false;
              this.isDisableSubmit = false
              setTimeout(() => this.seen = false, 10000);
            } else {
              let params = {};
              params["amount"] = this.amount;
              params["currency"] = this.currency;
              let reasons = this.reason;
              reasons += (this.checkReference === "yes") ? '|Bank Transfer' : '|Cash';
              params["reason"] = reasons;
              params["direction"] = "CREDIT_USER";
              params["customerMessage"] = this.custMsg;
              params["comment"] = this.comments;
              params["transactionType"] = this.trType;
              params["userId"] = this.userId;
              params["userType"] = "USER";
              params["walletId"] = this.walletId;
              let earmarkRecord = false;
              if (this.hiddenChecked === false) {
                earmarkRecord = true;
                let expr_at = this.expiredDate + ' ' + this.expiredTime;
                params["earmarkFeature"] = this.feature;
                params["earmarkExpiry"] = new Date(expr_at).toISOString();
              }
              params["earmarkRecord"] = earmarkRecord;
              TransactService.systemTransactionCreate(params).then(async resp => {
                if (resp.statusCode === 'S0000') {
                  this.isDisableSubmit = false
                  let params_matching = {};
                  params_matching["bank_reference"] = this.comments
                  params_matching["matching"] = true
                  if (this.checkReference === "yes") {
                    params_matching["check_reference"] = true
                  }
                  TransactService.getMatchingBankReference(this.url_mg, this.xapikey, params_matching).then(async resp_matching => {
                    console.log(resp_matching)
                  })
                  this.trRef = resp.message;
                  this.seenOTP = true;
                  this.sent = true;
                  this.msg = "A request has been sent to your approver";
                  this.color = 'success';
                  this.completeSingleCredit();
                  this.spinner = false;
                  // try {
                  //   const check_earmark = await AuthService.earMarkByWalletId(this.walletId);
                  //   console.log(check_earmark);
                  //   var dis = this;
                  //   var arr_check = check_earmark.payload.filter(p => p.feature == dis.feature);
                  //
                  //   if (this.hiddenChecked === false) {
                  //     let paramEarmark = {};
                  //     paramEarmark["expireDate"] = new Date(expr_at).toISOString();
                  //     if (arr_check.length == 0) {
                  //       paramEarmark["amount"] = this.amount;
                  //       paramEarmark["currency"] = this.currency;
                  //       paramEarmark["feature"] = this.feature;
                  //       paramEarmark["userId"] = this.userId;
                  //       paramEarmark["walletId"] = this.walletId;
                  //
                  //       AuthService.earMarkSave(paramEarmark).then(resp_earmark => {
                  //         console.log(resp_earmark);
                  //         this.msg = (resp_earmark.statusCode !== "S0000") ? 'Ask approve credit user success, but failed to save earmark due to : ' + resp_earmark.message : 'Credit user with earmark successfully done';
                  //         this.seen = true;
                  //         this.spinner = false;
                  //         this.color = (resp_earmark.statusCode !== "S0000") ? 'warning' : 'success';
                  //         setTimeout(() => this.seen = false, 5000);
                  //       }, error => {
                  //         console.log(error);
                  //       });
                  //     } else {
                  //       paramEarmark["recordId"] = arr_check[0].id;
                  //       paramEarmark["amount"] = parseFloat(arr_check[0].amount) + parseFloat(this.amount);
                  //       AuthService.earMarkUpdate(paramEarmark).then(resp_earmark => {
                  //         console.log(resp_earmark);
                  //         this.msg = (resp_earmark.statusCode != "S0000") ? 'Credit user success, but failed to update earmark due to : ' + resp_earmark.message : 'Credit user with earmark successfully done';
                  //         this.seen = true;
                  //         this.spinner = false;
                  //         this.color = (resp_earmark.statusCode != "S0000") ? 'warning' : 'success';
                  //         setTimeout(() => this.seen = false, 5000);
                  //       }, error => {
                  //         console.log(error);
                  //       });
                  //     }
                  //   }
                  //   this.completeSingleCredit();
                  //   this.spinner = false;
                  // } catch (err) {
                  //   console.log(err);
                  // }
                } else {
                  this.msg = resp.message;
                  this.color = 'warning';
                  this.isDisableSubmit = false
                }
                this.seen = true;
                this.spinner = false;
                setTimeout(() => this.seen = false, 5000);

              }, error => {
                this.isDisableSubmit = false
                console.log(error);
              });
            }
          }
        }, error => {
          console.log(error);
        });

      },

      debitCreditValidateBackup: function() {
          AuthService.debitCreditValidate(false).then(resp => {
            if(resp.statusCode=='S0000'){
               this.trRef   = resp.message;
               this.seenOTP = true; this.sent = true;
               this.msg     = "An OTP Code has been sent to your Approver. Please request code from Approver!";
               this.color   = 'info';
            } else {
               this.msg   = resp.message;
               this.color = 'warning';
            }
            this.seen = true; this.spinner =false;
            setTimeout( () =>  this.seen=false , 5000);

           }, error => {
              console.log(error);
          });
      },

      creditUser: function(params) {
        AuthService.creditUser(params).then(resp => {
            //console.log(resp);
              if(resp.message=='Success'){
                this.msg   = 'Credit user successfully done';
                this.color = 'success';  this.seen  = true;
                this.phoneNum='';
                this.userId='';
                this.displayName='';
                this.walletId='';
                this.currency='';
                this.amount='';
                this.custMsg='';
                this.reason='';
                //this.seen=false;
              } else {
                this.msg   = resp.data.message;
                this.color = 'warning'; this.seen  = true;
                setTimeout( () =>  this.seen=false , 5000);
              }
        }, error => {
            console.log(error);
        });
      },

      getUserDetail: function(phoneNumber) {
					if(phoneNumber !== undefined && phoneNumber !== "") {
            AuthService.getUsrDtlByPhone(phoneNumber).then(resp => {  //console.log(resp);
              if (resp.hasOwnProperty('userIdentify')) {
                this.userId = resp.userIdentify.userId;
                var items = resp.wallets;
                var wallets = [];
                for (var key in items) {
                  var WalletId = items[key].walletId;
                  var WalletName = items[key].walletGroup.name;
                  wallets.push({value: WalletId, text: WalletName});
                }
                var displayName = resp.firstName + " " + resp.lastName;
                this.displayName = displayName.trim();
                this.optWallet = wallets;
                //this.optWallet = this.walletGroup;
                if (this.seenuser) this.seenuser = false;
              } else {
                this.displayName = "";
                this.userId = "";
                this.seenuser = true;
                document.getElementById("phoneNumberSearch").style.border = "1px solid red";
                this.coloruser="danger"; this.msguser = "User Not Found";
                var dis = this;
                setTimeout( function(){ dis.seenuser=false; document.getElementById("phoneNumberSearch").style.removeProperty("border"); } , 5000);
              }
            }, error => {
              console.log(error);
              this.loading = false;
              document.getElementById("phoneNumberSearch").style.border = "1px solid red";
            });
					} else {
							document.getElementById("phoneNumberSearch").style.border = "1px solid red";
							this.coloruser="danger"; this.msguser = "User Not Found";
							this.seenuser=true;
							var dis = this;
							setTimeout( function(){ dis.seenuser=false; document.getElementById("phoneNumberSearch").style.removeProperty("border"); } , 5000);
					}
      },

      getWalletGroup: function(code) {
          AuthService.getWalletGroup(code).then(resp => { console.log(resp);

            //  this.groupUniqueNumber = resp.groupUniqueNumber;
            //  this.name = resp.name;
            //  this.follusergroup = resp.followUserGroup;

          }, error => {
            console.log(error);
          });
      },

      getWalletGroupList: function() {
        AuthService.getWalletGroupList().then(resp => {  //console.log(resp);

             var items = resp; var wallets = [];
             for (var key in items) {
                var WalletId   = items[key].id;
                var WalletName = items[key].name;
                wallets.push({ value: WalletId  , text: WalletName });
              }
              this.walletGroup = wallets;

        }, error => {
          this.loading = false;
          console.log(error);
        });
      },

			sortByProperty(property){
         return function(a,b){
            if(a[property] > b[property])
               return 1;
            else if(a[property] < b[property])
               return -1;
            return 0;
         }
      },


      getSystemConfigList: function() {
        AuthService.getSystemConfigList().then(resp => {
          // console.log(resp)
          let external_config = JSON.parse(resp.external_api_call_config)
          // console.log(external_config)
          this.url_mg = external_config.hasOwnProperty('mg-api-url-credit') ? external_config['mg-api-url-credit'] : 'https://transact.moolahpay.co/';
          this.xapikey = external_config.hasOwnProperty('x-api-key-credit') ? external_config['x-api-key-credit'] : ''
          console.log(this.url_mg)
          console.log(this.xapikey)
        })
      },

      getConfigList: function() {
          AuthService.getConfigList().then(resp => {   //console.log(resp);

            var currSrc=[]; var temp = [];
            var arrcurrency = resp.currency;
            for (const item of arrcurrency){ temp.push(item); }
            temp.sort();
            for (var key in temp) {
                var curr = temp[key];
                currSrc.push({ value: curr , text: curr });
            }
            this.optCurrency = currSrc;

            var typeSrc=[];
            var arrTypes = resp.systemTransactionTypes;
            for (const item2 of arrTypes) {
                if(item2 !== "REDEEM") { typeSrc.push({ value: item2 , text: item2 }); }
            }

            this.optTrType = typeSrc;

						var gFeatSrc=[]; var temp = []; var baseFeatures = [];
						var arr_notincludes = ['P2P_OUTSIDE_GROUP', 'P2P_OUTSIDE_COUNTRY', 'P2P_SEND_UNREG_USER', 'REQUEST_MONEY', 'P2M_PREFERRED_PARTNERS_ONLY', 'BY_INVITE', 'BY_REQUEST', 'TOPUPCARD', 'TOPUP_CHECKOUT', 'TOPUP_BANK', 'TOPUP_AUTO'];
						var groupFeatures = resp.groupFeatures;
						for (var key in groupFeatures) {
								if(key == 0) this.feature = groupFeatures[key];
								var gFeat = groupFeatures[key];
								if(!arr_notincludes.includes(gFeat)) {
										gFeatSrc.push({ value: gFeat, text: gFeat });
								}
						}
						gFeatSrc.sort(this.sortByProperty("value"));
						this.optFeatures = gFeatSrc;
           }, error => {
            console.log(error);
          });
      },
    }
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="vue2-timepicker/dist/VueTimepicker.css"></style>
<style>
/* .list-group shadow vbt-autcomplete-list {  background: black; } */
[role~=listbox]{ background: black; }
</style>
